export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"},{"name":"robots","content":"noindex,nofollow"},{"name":"description","content":"IFSTAGE."},{"name":"HandheldFriendly","content":"true"},{"property":"og:title","content":"IFSTAGE."},{"hid":"og:locale","property":"og:locale","content":"en_US"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.png","sizes":"16x16"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png","sizes":"180x180"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#6366f1"}],"style":[],"script":[],"noscript":[],"title":"IFSTAGE Co.,Ltd - Official Web Site","htmlAttrs":{"lang":"en"}}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appRootId = "__ifstage"

export const appBuildAssetsDir = "/_ifstage/"

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__ifstage'