import revive_payload_client_R5ykwWmbzk from "/home/runner/work/ifstage/ifstage/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.7.4_@types+node@20.8.3_sass@1.69.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kgxYV1dL9A from "/home/runner/work/ifstage/ifstage/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.7.4_@types+node@20.8.3_sass@1.69.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0KuZlf4ASc from "/home/runner/work/ifstage/ifstage/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.7.4_@types+node@20.8.3_sass@1.69.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/ifstage/ifstage/.nuxt/components.plugin.mjs";
import prefetch_client_GEXFfXf9OR from "/home/runner/work/ifstage/ifstage/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.7.4_@types+node@20.8.3_sass@1.69.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_6RoLLwfmQ3 from "/home/runner/work/ifstage/ifstage/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.4_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_KND4NDsJ8G from "/home/runner/work/ifstage/ifstage/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.4_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_D28BzSFR7t from "/home/runner/work/ifstage/ifstage/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.7.4_@types+node@20.8.3_sass@1.69.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_R5ykwWmbzk,
  unhead_kgxYV1dL9A,
  router_0KuZlf4ASc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GEXFfXf9OR,
  composition_6RoLLwfmQ3,
  i18n_KND4NDsJ8G,
  chunk_reload_client_D28BzSFR7t
]