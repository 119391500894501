import { default as contactw5C4uxL65MMeta } from "/home/runner/work/ifstage/ifstage/pages/contact.vue?macro=true";
import { default as indexDlCVE9kJzKMeta } from "/home/runner/work/ifstage/ifstage/pages/index.vue?macro=true";
import { default as servicejOA7gTpL6lMeta } from "/home/runner/work/ifstage/ifstage/pages/service.vue?macro=true";
import { default as testF1Od1YhMGMMeta } from "/home/runner/work/ifstage/ifstage/pages/test.vue?macro=true";
import { default as worku9364JgPOdMeta } from "/home/runner/work/ifstage/ifstage/pages/work.vue?macro=true";
import { default as adidaskYQHlKJzFTMeta } from "/home/runner/work/ifstage/ifstage/pages/works/adidas.vue?macro=true";
import { default as awwebpnCUKtxDMeta } from "/home/runner/work/ifstage/ifstage/pages/works/aww.vue?macro=true";
import { default as le7gLsJAMe55Meta } from "/home/runner/work/ifstage/ifstage/pages/works/le.vue?macro=true";
import { default as neo5i2DjJtkU2Meta } from "/home/runner/work/ifstage/ifstage/pages/works/neo.vue?macro=true";
import { default as obajiEOa9D3q8T9Meta } from "/home/runner/work/ifstage/ifstage/pages/works/obaji.vue?macro=true";
import { default as rapasR1GSR1EQtwMeta } from "/home/runner/work/ifstage/ifstage/pages/works/rapas.vue?macro=true";
import { default as sdpijQAu6H4jyMeta } from "/home/runner/work/ifstage/ifstage/pages/works/sdp.vue?macro=true";
export default [
  {
    name: contactw5C4uxL65MMeta?.name ?? "contact___en",
    path: contactw5C4uxL65MMeta?.path ?? "/contact",
    meta: contactw5C4uxL65MMeta || {},
    alias: contactw5C4uxL65MMeta?.alias || [],
    redirect: contactw5C4uxL65MMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactw5C4uxL65MMeta?.name ?? "contact___ja",
    path: contactw5C4uxL65MMeta?.path ?? "/ja/contact",
    meta: contactw5C4uxL65MMeta || {},
    alias: contactw5C4uxL65MMeta?.alias || [],
    redirect: contactw5C4uxL65MMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexDlCVE9kJzKMeta?.name ?? "index___en",
    path: indexDlCVE9kJzKMeta?.path ?? "/",
    meta: indexDlCVE9kJzKMeta || {},
    alias: indexDlCVE9kJzKMeta?.alias || [],
    redirect: indexDlCVE9kJzKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexDlCVE9kJzKMeta?.name ?? "index___ja",
    path: indexDlCVE9kJzKMeta?.path ?? "/ja",
    meta: indexDlCVE9kJzKMeta || {},
    alias: indexDlCVE9kJzKMeta?.alias || [],
    redirect: indexDlCVE9kJzKMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/index.vue").then(m => m.default || m)
  },
  {
    name: servicejOA7gTpL6lMeta?.name ?? "service___en",
    path: servicejOA7gTpL6lMeta?.path ?? "/service",
    meta: servicejOA7gTpL6lMeta || {},
    alias: servicejOA7gTpL6lMeta?.alias || [],
    redirect: servicejOA7gTpL6lMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/service.vue").then(m => m.default || m)
  },
  {
    name: servicejOA7gTpL6lMeta?.name ?? "service___ja",
    path: servicejOA7gTpL6lMeta?.path ?? "/ja/service",
    meta: servicejOA7gTpL6lMeta || {},
    alias: servicejOA7gTpL6lMeta?.alias || [],
    redirect: servicejOA7gTpL6lMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/service.vue").then(m => m.default || m)
  },
  {
    name: testF1Od1YhMGMMeta?.name ?? "test___en",
    path: testF1Od1YhMGMMeta?.path ?? "/test",
    meta: testF1Od1YhMGMMeta || {},
    alias: testF1Od1YhMGMMeta?.alias || [],
    redirect: testF1Od1YhMGMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/test.vue").then(m => m.default || m)
  },
  {
    name: testF1Od1YhMGMMeta?.name ?? "test___ja",
    path: testF1Od1YhMGMMeta?.path ?? "/ja/test",
    meta: testF1Od1YhMGMMeta || {},
    alias: testF1Od1YhMGMMeta?.alias || [],
    redirect: testF1Od1YhMGMMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/test.vue").then(m => m.default || m)
  },
  {
    name: worku9364JgPOdMeta?.name ?? "work___en",
    path: worku9364JgPOdMeta?.path ?? "/work",
    meta: worku9364JgPOdMeta || {},
    alias: worku9364JgPOdMeta?.alias || [],
    redirect: worku9364JgPOdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/work.vue").then(m => m.default || m)
  },
  {
    name: worku9364JgPOdMeta?.name ?? "work___ja",
    path: worku9364JgPOdMeta?.path ?? "/ja/work",
    meta: worku9364JgPOdMeta || {},
    alias: worku9364JgPOdMeta?.alias || [],
    redirect: worku9364JgPOdMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/work.vue").then(m => m.default || m)
  },
  {
    name: adidaskYQHlKJzFTMeta?.name ?? "works-adidas___en",
    path: adidaskYQHlKJzFTMeta?.path ?? "/works/adidas",
    meta: adidaskYQHlKJzFTMeta || {},
    alias: adidaskYQHlKJzFTMeta?.alias || [],
    redirect: adidaskYQHlKJzFTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/adidas.vue").then(m => m.default || m)
  },
  {
    name: adidaskYQHlKJzFTMeta?.name ?? "works-adidas___ja",
    path: adidaskYQHlKJzFTMeta?.path ?? "/ja/works/adidas",
    meta: adidaskYQHlKJzFTMeta || {},
    alias: adidaskYQHlKJzFTMeta?.alias || [],
    redirect: adidaskYQHlKJzFTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/adidas.vue").then(m => m.default || m)
  },
  {
    name: awwebpnCUKtxDMeta?.name ?? "works-aww___en",
    path: awwebpnCUKtxDMeta?.path ?? "/works/aww",
    meta: awwebpnCUKtxDMeta || {},
    alias: awwebpnCUKtxDMeta?.alias || [],
    redirect: awwebpnCUKtxDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/aww.vue").then(m => m.default || m)
  },
  {
    name: awwebpnCUKtxDMeta?.name ?? "works-aww___ja",
    path: awwebpnCUKtxDMeta?.path ?? "/ja/works/aww",
    meta: awwebpnCUKtxDMeta || {},
    alias: awwebpnCUKtxDMeta?.alias || [],
    redirect: awwebpnCUKtxDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/aww.vue").then(m => m.default || m)
  },
  {
    name: le7gLsJAMe55Meta?.name ?? "works-le___en",
    path: le7gLsJAMe55Meta?.path ?? "/works/le",
    meta: le7gLsJAMe55Meta || {},
    alias: le7gLsJAMe55Meta?.alias || [],
    redirect: le7gLsJAMe55Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/le.vue").then(m => m.default || m)
  },
  {
    name: le7gLsJAMe55Meta?.name ?? "works-le___ja",
    path: le7gLsJAMe55Meta?.path ?? "/ja/works/le",
    meta: le7gLsJAMe55Meta || {},
    alias: le7gLsJAMe55Meta?.alias || [],
    redirect: le7gLsJAMe55Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/le.vue").then(m => m.default || m)
  },
  {
    name: neo5i2DjJtkU2Meta?.name ?? "works-neo___en",
    path: neo5i2DjJtkU2Meta?.path ?? "/works/neo",
    meta: neo5i2DjJtkU2Meta || {},
    alias: neo5i2DjJtkU2Meta?.alias || [],
    redirect: neo5i2DjJtkU2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/neo.vue").then(m => m.default || m)
  },
  {
    name: neo5i2DjJtkU2Meta?.name ?? "works-neo___ja",
    path: neo5i2DjJtkU2Meta?.path ?? "/ja/works/neo",
    meta: neo5i2DjJtkU2Meta || {},
    alias: neo5i2DjJtkU2Meta?.alias || [],
    redirect: neo5i2DjJtkU2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/neo.vue").then(m => m.default || m)
  },
  {
    name: obajiEOa9D3q8T9Meta?.name ?? "works-obaji___en",
    path: obajiEOa9D3q8T9Meta?.path ?? "/works/obaji",
    meta: obajiEOa9D3q8T9Meta || {},
    alias: obajiEOa9D3q8T9Meta?.alias || [],
    redirect: obajiEOa9D3q8T9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/obaji.vue").then(m => m.default || m)
  },
  {
    name: obajiEOa9D3q8T9Meta?.name ?? "works-obaji___ja",
    path: obajiEOa9D3q8T9Meta?.path ?? "/ja/works/obaji",
    meta: obajiEOa9D3q8T9Meta || {},
    alias: obajiEOa9D3q8T9Meta?.alias || [],
    redirect: obajiEOa9D3q8T9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/obaji.vue").then(m => m.default || m)
  },
  {
    name: rapasR1GSR1EQtwMeta?.name ?? "works-rapas___en",
    path: rapasR1GSR1EQtwMeta?.path ?? "/works/rapas",
    meta: rapasR1GSR1EQtwMeta || {},
    alias: rapasR1GSR1EQtwMeta?.alias || [],
    redirect: rapasR1GSR1EQtwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/rapas.vue").then(m => m.default || m)
  },
  {
    name: rapasR1GSR1EQtwMeta?.name ?? "works-rapas___ja",
    path: rapasR1GSR1EQtwMeta?.path ?? "/ja/works/rapas",
    meta: rapasR1GSR1EQtwMeta || {},
    alias: rapasR1GSR1EQtwMeta?.alias || [],
    redirect: rapasR1GSR1EQtwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/rapas.vue").then(m => m.default || m)
  },
  {
    name: sdpijQAu6H4jyMeta?.name ?? "works-sdp___en",
    path: sdpijQAu6H4jyMeta?.path ?? "/works/sdp",
    meta: sdpijQAu6H4jyMeta || {},
    alias: sdpijQAu6H4jyMeta?.alias || [],
    redirect: sdpijQAu6H4jyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/sdp.vue").then(m => m.default || m)
  },
  {
    name: sdpijQAu6H4jyMeta?.name ?? "works-sdp___ja",
    path: sdpijQAu6H4jyMeta?.path ?? "/ja/works/sdp",
    meta: sdpijQAu6H4jyMeta || {},
    alias: sdpijQAu6H4jyMeta?.alias || [],
    redirect: sdpijQAu6H4jyMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ifstage/ifstage/pages/works/sdp.vue").then(m => m.default || m)
  }
]