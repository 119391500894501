<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
input {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}
</style>
